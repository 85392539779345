import {
  Col,
  Row,
  Spin,
} from 'antd';
import LayoutBase from './LayoutBase';

export default function LoadingPage() {
  return (
    <LayoutBase title="Loading">
      <Row
        justify="center"
        align="middle"
        style={{ minHeight: '100vh', backgroundColor: '#f5f5f5' }}
      >
        <Col>
          <Spin size="large" tip="Loading and securing connection" />
        </Col>
      </Row>
    </LayoutBase>
  );
}
